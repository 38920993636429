<!-- 2017/5/26- yyy-404页面-->
<template>
  <section class="page__404" :style="{height:height+'px'}">
    <div class="error-container">
      <div class="page__error">
        <div id="pacman"></div>
      </div>
      <div class="page__container">
        <div class="page__title">
          <h1>{{$t('error.noDome')}}</h1>
        </div>
        <div class="page__content clearfix">
          <div class="left">
            <p>{{$t('error.causedBy')}}</p>
            <ol type="I">
              <li>{{$t('error.urlErr')}}</li>
              <li>{{$t('error.urlFaild')}}</li>
              <li>{{$t('error.other')}}</li>
            </ol>
          </div>
          <div class="right">
            <p>{{$t('error.eg')}}</p>
            <ol class="links" type="I">
              <li><a href="http://www.magicee.cn/">» 深圳市维超智能科技有限官网</a></li>
              <li><a href="http://en.magicee.cn/#/">» Shenzhen Weichao Intelligent Technology Co., LTD</a></li>
              <!-- <li><a href="http://www.chinarising.com.cn/">» 美盛文化</a></li> -->
            </ol>
          </div>
        </div>
        <div class="page__footer">
          粤ICP备17107183号-1 粤公网安备 44030502008187号
        </div>
      </div>
    </div>
  </section>
</template>

<script>

  export default {
    data:()=>{
      return {
        height:"",
      }
    },
    mounted(){
      this.icon();
      let ele = document.getElementById("pacman");
      this.toggleClass(ele, "pacman_eats");
    },
    methods:{
      icon(){
        let _this = this;
        _this.height = window.innerHeight;
        window.onresize=function(){ // 屏幕变化时，高度自适应
          _this.height = window.innerHeight;
        };
        setInterval(function(){
          let ele = document.getElementById("pacman");
          _this.toggleClass(ele, "pacman_eats");
        }, 300);
        let current = 0;
        let errAll = document.getElementsByClassName("page__404")[0];
        setInterval(function(){
          current -= 1;
          errAll.style.backgroundPosition = current + "px";
        }, 50);
      },
      hasClass(ele, cls) {
        return ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
      },
      addClass(ele, cls) {
        let _this = this;
        if(!_this.hasClass(ele, cls)) ele.className += " " + cls;
      },
      removeClass(ele, cls) {
        let _this = this;
        if (_this.hasClass(ele, cls)) {
          let reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
          ele.className = ele.className.replace(reg, " ");
        }
      },
      toggleClass(ele, cls) {
        let _this = this;
        if(_this.hasClass(ele, cls)) {
          _this.removeClass(ele, cls);
        } else {
          _this.addClass(ele, cls);
        }
      }
    }
  
  }
</script>

<style lang="less" scoped>

  .page__404 {
    width: 100%;
    height: 100%;
    text-shadow: 0 1px 0 #ffffff;
    filter: dropshadow(color=#ffffff, offx=0, offy=1);
    text-align: center;
    background-color:#aaa;
    background-image:url('./images/maze_bg.png');
    overflow: hidden;
  }
  .page__error {
    background:transparent url('./images/404e.png') right 35px no-repeat;
  }
  .page__error #pacman {
    background:transparent url('./images/pacman_eats.png') 0 0 no-repeat;
  }
  .pacman_eats {
    background:transparent url('./images/pacman_eats.png') 0 -207px no-repeat!important;
  }
  .page__container {
    width:760px;
    margin:0 auto;
    border:1px solid #E0E0E0;
    border-top:1px solid #fff;
    border-bottom:1px solid #ccc;
    background-color:#E5E5E5;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 2px 4px rgba(0, 0, 0, 0.7);
  }
  .page__container .page__title {
    border-bottom:1px solid #aaa;
    overflow:hidden;
    background-color:#E7E7E7;
    text-align: center;
  }
  .page__container .page__content {
    border-top:1px solid #fff;
    border-bottom:1px solid #aaa;
  }
  .page__container .page__content ol{
    margin-top: 10px;
    li {
      font-size: 16px;
      margin: 8px 0;
      a {
        color: #000000;
        font-weight: bold;
        transition: all 0.1s linear;
        &:hover {
          color: #f9798e;
        }
      }
    }
  }
  .page__container .page__footer {
     padding:10px 20px 10px 20px;
     border-top:1px solid #f5f5f5;
     font-size:14px;
     color: #555;
     background-color:#D5D5D5;
   }
  .page__container .left {
    padding:30px 10px 20px 50px;
    width:350px;
    float:left;
  }
  .page__container .right {
    padding:30px 20px 20px 10px;
    width:320px;
    float:right;
  }

  .error-container {
    display:block;
    text-align: left;
    width: 960px;
    margin: 0 auto;
    padding-top:40px;
    padding-bottom:40px;
  }
  .page__error {
    background:transparent url('./images/404e.png') right 35px no-repeat;
    width:680px;
    height:280px;
    position:relative;
    margin:0 auto;
  }
  .page__error #pacman {
    width:207px;
    height:207px;
    position:absolute;
    left:0px;
    top:16px;
    background:transparent url('./images/pacman_eats.png') 0 0 no-repeat;
    animation:eats 2s linear 0s infinite alternate;
  }
  .pacman_eats {
    background:transparent url('./images/pacman_eats.png') 0 -207px no-repeat!important;
  }
  @keyframes eats {
    from {
      left:0px; top:16px;
    }
    to {
      left:200px; top:16px;
    }
  }

</style>